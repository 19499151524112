import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { GlobalState } from './global.reducer';
import { RouterState } from './router-state';

export const selectRouterReducerState = createFeatureSelector<RouterReducerState<RouterState>>('router');
export const selectRouterState = createSelector(selectRouterReducerState, (routerState) => routerState?.state);
export const selectRoute = createSelector(selectRouterState, (state) => state?.route);

export const selectGlobalState = createFeatureSelector<GlobalState>('global');
export const selectIsACP = createSelector(selectGlobalState, (state) => state.acp);
export const selectBackLink = createSelector(selectGlobalState, ({ backLink }) => backLink);
